<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="means-box">
        <div class="d-flex align-items-center">
          <span class="title-sign"></span>
          <h2 class="authentication-box">{{ $t('stores.merAuthInfo') }}</h2>
        </div>
        <div class="authentication-content">
          <div class="content-box w-100">
            <span class="content-box-title">{{ $t('record.merType') }}</span>
            <span class="content-box-value" v-if="storeMessage.merType===1">{{ $t('stores.person') }}</span>
            <span class="content-box-value" v-else>{{ $t('stores.company') }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.companyName">
            <span class="content-box-title">{{ $t('stores.companyName') }}</span>
            <span class="content-box-value">{{ storeMessage.companyName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.licenseNumber">
            <span class="content-box-title">{{$t('stores.businessLicenseCode')}}</span>
            <span class="content-box-value">{{ storeMessage.licenseNumber }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.licenseCover">
            <span class="content-box-title">{{$t('stores.frontPhotoBusinessLicense')}}</span>
            <img class="object-fit-cover" :src="storeMessage.licenseCover" width="100" height="100" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.idCardName">
            <span class="content-box-title">{{$t('stores.legalPersonName')}}</span>
            <span class="content-box-value">{{ storeMessage.idCardName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.idCardNumber">
            <span class="content-box-title">{{$t('stores.legalPersonNumber')}}</span>
            <span class="content-box-value">{{ storeMessage.idCardNumber }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.frontIdCard">
            <span class="content-box-title">{{$t('stores.frontIdCard')}}</span>
            <img class="object-fit-cover" :src="storeMessage.frontIdCard" width="100" height="100" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.reverseIdCard">
            <span class="content-box-title">{{$t('stores.reverseIdCard')}}</span>
            <img class="object-fit-cover" :src="storeMessage.reverseIdCard" width="100" height="100" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.takingFrontIdCard">
            <span class="content-box-title">{{$t('stores.takingFrontIdCard')}}</span>
            <img class="object-fit-cover" :src="storeMessage.takingFrontIdCard" width="100" height="100" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.takingReverseIdCard">
            <span class="content-box-title">{{$t('stores.takingReverseIdCard')}}</span>
            <img class="object-fit-cover" :src="storeMessage.takingReverseIdCard" width="100" height="100" alt="">
          </div>
        </div>

        <div class="d-flex align-items-center">
          <span class="title-sign"></span>
          <h2 class="authentication-box">{{$t('stores.merInformation')}}</h2>
        </div>
        <div class="authentication-content">
          <div class="content-box w-100">
            <span class="content-box-title">{{$t('record.openSite')}}</span>
            <span class="content-box-value">{{ storeMessage.sites}}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merName">
            <span class="content-box-title">{{$t('stores.merName')}}</span>
            <span class="content-box-value">{{ storeMessage.merName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merCateName">
            <span class="content-box-title">{{$t('stores.merClass')}}</span>
            <span>{{ storeMessage.merCateName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.logo">
            <span class="content-box-title">{{$t('stores.merLogo')}}</span>
            <img class="object-fit-cover" :src="storeMessage.logo" width="100" height="100" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.merInfo">
            <span class="content-box-title">{{$t('stores.merInfo')}}</span>
            <span class="">{{ storeMessage.merInfo }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merAddress">
            <span class="content-box-title">{{$t('stores.merAddress')}}</span>
            <span>{{ storeMessage.merAddress }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { storeInformation } from '@/api/base'
export default {
  data() {
    return {
      dialogVisible: false,
      id: '',
      storeMessage: {},
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    // 获取店铺信息
    getStoreInformation() {
      storeInformation(this.id).then(res => {
        this.storeMessage = res.data
        console.log(res.data, '123456');
      })
    },
  }
}
</script>
<style scoped>
.means-box {
  padding: 0 10px;
}
.title-sign {
  width: 4px;
  height: 20px;
  background: #4285f7;
  margin-right: 10px;
}
.authentication-content {
  padding: 60px 5%;
}
.content-box {
  display: flex;
  margin-bottom: 30px;
}
.content-box-title {
  min-width: 200px;
  text-align: right;
  margin-right: 20px;
}
</style>
