<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :close-on-click-modal="false">
      <h2 class="tip-title pad-bot-20">{{ $t('openTips.tipsTitle') }}</h2>
      <span>{{ $t('openTips.tips1', { merchant: storeName}) }}{{ $t('openTips.tips2') }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('base.cancel') }}</el-button>
        <el-button type="primary" @click="finalTrue()">{{ $t('base.confirm') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleSuccess" width="30%" :before-close="handleCloseSuccess" :close-on-click-modal="false">
      <h2 class="tip-title pad-10-0">{{ $t('openTips.successSubmitAudit') }}</h2>
      <div class="d-flex flex-direction-column align-items-center pad-10-0">
        <img class="success-logo" width="60" height="60" src="@/assets/image/home/ic_gong.png" alt="">
      </div>
      <span>{{$t('stores.submitTips')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="finalTrueSuccess()">{{ $t('base.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { oneClickShop } from '@/api/base'
export default {
  data() {
    return {
      storeName: '',
      dialogVisible: false,
      dialogVisibleSuccess: false,
      siteId: '',
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    finalTrue() {
      this.dialogVisible = false
      // eslint-disable-next-line no-unused-vars
      oneClickShop(this.siteId).then(_ => {
        this.dialogVisibleSuccess = true
      })
    },
    finalTrueSuccess() {
      this.dialogVisibleSuccess = false
      this.$parent.getPageRecord()
      this.$parent.getUnopenedSiteList()
    },
    handleCloseSuccess() {
      this.dialogVisibleSuccess = false
    }
  }
}
</script>
<style scoped>
.tip-title {
  text-align: center;
}
</style>
