<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="means-box">
        <div class="d-flex align-items-center">
          <span class="title-sign"></span>
          <h2 class="authentication-box">{{$t('stores.merAuthInfo')}}</h2>
        </div>
        <div class="authentication-content">
          <div class="content-box w-100">
            <span class="content-box-title">{{$t('record.merType')}}</span>
            <span class="content-box-value" v-if="storeMessage.merType===1">{{$t('stores.person')}}</span>
            <span class="content-box-value" v-else>{{$t('stores.company')}}</span>
          </div>
          <div class="content-box w-100">
            <span class="content-box-title">{{$t('stores.certificateType')}}</span>
            <span class="content-box-value" v-if="storeMessage.idCardType===1">{{$t('stores.idCard')}}</span>
            <span class="content-box-value" v-else-if="storeMessage.idCardType===2">{{$t('stores.passport')}}</span>
            <span class="content-box-value" v-else-if="storeMessage.idCardType===3">{{$t('stores.driveLicense')}}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.idCardName">
            <span class="content-box-title">{{$t('stores.documentName')}}</span>
            <span class="content-box-value">{{ storeMessage.idCardName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.idCardNumber">
            <span class="content-box-title">{{$t('stores.documentNumber')}}</span>
            <span class="content-box-value">{{ storeMessage.idCardNumber }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.frontIdCard">
            <span class="content-box-title">{{$t('stores.idPhoto')}}</span>
            <img class="object-fit-cover" :src="storeMessage.frontIdCard" width="86" height="86" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.reverseIdCard">
            <span class="content-box-title">{{$t('stores.idPhotoBack')}}</span>
            <img class="object-fit-cover" :src="storeMessage.reverseIdCard" width="86" height="86" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.bankPic">
            <span class="content-box-title">{{$t('stores.bankCardPhoto')}}</span>
            <img class="object-fit-cover" :src="storeMessage.bankPic" width="86" height="86" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.bankAccount">
            <span class="content-box-title">{{$t('stores.accountName')}}</span>
            <span class="content-box-value">{{ storeMessage.bankAccount }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.bankNumber">
            <span class="content-box-title">{{$t('stores.accountNumber')}}</span>
            <span class="content-box-value">{{ storeMessage.bankNumber }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.bankName">
            <span class="content-box-title">{{$t('stores.bank')}}</span>
            <span class="content-box-value">{{ storeMessage.bankName }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span class="title-sign"></span>
          <h2 class="authentication-box">{{$t('stores.merInformation')}}</h2>
        </div>
        <div class="authentication-content">
          <div class="content-box w-100">
            <span class="content-box-title">{{$t('record.openSite')}}</span>
            <span class="content-box-value">{{ storeMessage.sites}}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merName">
            <span class="content-box-title">{{$t('stores.merName')}}</span>
            <span class="content-box-value">{{ storeMessage.merName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merCateName">
            <span class="content-box-title">{{$t('stores.merClass')}}</span>
            <span>{{ storeMessage.merCateName }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.logo">
            <span class="content-box-title">{{$t('stores.merLogo')}}</span>
            <img class="object-fit-cover" :src="storeMessage.logo" width="86" height="86" alt="">
          </div>
          <div class="content-box w-100" v-if="storeMessage.merInfo">
            <span class="content-box-title">{{$t('stores.merInfo')}}</span>
            <span class="">{{ storeMessage.merInfo }}</span>
          </div>
          <div class="content-box w-100" v-if="storeMessage.merAddress">
            <span class="content-box-title">{{$t('stores.merAddress')}}</span>
            <span>{{ storeMessage.merAddress }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { storeInformation } from '@/api/base'
export default {
  data() {
    return {
      dialogVisible: false,
      id: '',
      storeMessage: {},
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    // 获取店铺信息
    getStoreInformation() {
      storeInformation(this.id).then(res => {
        this.storeMessage = res.data
        console.log(res.data, '123456');
      })
    },
  }
}
</script>
<style scoped>
.means-box {
  padding: 0 10px;
}
.title-sign {
  width: 4px;
  height: 20px;
  background: #4285f7;
  margin-right: 10px;
}
.authentication-content {
  padding: 60px 5%;
}
.content-box {
  display: flex;
  margin-bottom: 30px;
}
.content-box-title {
  min-width: 200px;
  text-align: right;
  margin-right: 20px;
}
</style>
