<template>
  <div class="record-box">
    <div class="record-box-child w-100 pad-20-10 d-flex flex-direction-column align-items-center">
      <div class="d-flex justify-space-between w-100">
        <div class="font-weight-bold">{{ $t('record.applicationRecord') }}</div>
        <div class="d-flex" v-if="area === 86 && showApplication">
          <div class="site-select mar-left-50 d-flex align-items-center">
            <el-select v-model="siteId" :placeholder="$t('record.pleaseSelectSite')" @change="selectSite">
              <el-option
                  v-for="item in unopenedSiteList"
                  :key="item.id"
                  :label="item.siteName"
                  :value="item.id"
              ></el-option>
              <template #empty>{{ $t('record.noSite') }}</template>
            </el-select>
          </div>
          <div class="mar-left-50">
            <el-button
                style="color:#fff;background:#4285F7;border-color:#4285F7;"
                @click="oneClickApplication()"
            >{{ $t('record.oneClickApplication') }}
            </el-button>
          </div>
          <openingReminder ref="openingReminderDialog"/>
        </div>
      </div>
      <div class="pad-top-20 w-100" v-loading="tableLoading">
        <el-table
            :data="tableData"
            style="width: 100%"
            class="myNoteBox"
            :header-cell-style="{ background: '#F5F6F8', fontWeight: '500' }"
        >
          <template slot="empty">
            <div class="empty-box">
              <img
                  style="margin-top:50px"
                  src="@/assets/image/home/bg_wu.png"
                  width="90"
                  height="90"
                  alt
              />
              <div>{{ $t('record.submitTips') }}</div>
              <el-button
                  style="margin-top: 20px;background: #4285F7;border: 1px solid #4285F7;color: white"
                  @click="immediatelyBtn()"
              >{{ $t('record.admissionNow') }}
              </el-button>
            </div>
          </template>
          <el-table-column type="index" :label="$t('record.serialNumber')" width="100" align="center"></el-table-column>
          <el-table-column
              class="mon-text-overflow-2"
              prop="merName"
              :label="$t('record.merName')"
              width="120"
              align="center"
          ></el-table-column>
          <el-table-column :label="$t('record.merType')" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.merType | merchantType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="siteName" :label="$t('record.openSite')" width="100" align="center"></el-table-column>
          <el-table-column prop="merchantCreateTime" :label="$t('record.initialTime')" width="200"
                           align="center"></el-table-column>
          <el-table-column prop="createTime" :label="$t('record.statusUpdateTime')" align="center"></el-table-column>
          <el-table-column :label="$t('record.approvalStatus')" width="120" align="center">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  style="color:#f39923;text-align: center;"
                  :class="{ stateStyleOne: scope.row.newStatus == 1, stateStyleTwo: scope.row.newStatus == 2, stateStyleThree: scope.row.newStatus == 3, stateStyleFour: scope.row.newStatus == 4 }"
                  size="small"
                  @click="explain(scope.row)"
              >
                {{ scope.row.newStatus | latestStatus }}
                <i class="el-icon-arrow-right"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" :label="$t('record.operate')">
            <template slot-scope="scope">
              <el-button
                  style="margin: 2px"
                  size="mini"
                  v-if="scope.row.newStatus === 1 || scope.row.newStatus === 3"
                  @click="editMaterial(scope.row)"
              >{{ $t('record.editMaterial') }}
              </el-button>
              <el-button
                  style="margin: 2px"
                  size="mini"
                  v-else-if="scope.row.newStatus === 2"
                  @click="recall(scope.row)"
              >{{ $t('record.withdrawData') }}
              </el-button>
              <el-button
                  style="margin: 2px"
                  type="primary"
                  size="mini"
                  v-else-if="scope.row.newStatus === 4"
                  @click="nowOpenShop(scope.row)"
              >{{ $t('record.shopNow') }}
              </el-button>
              <el-button
                  style="margin: 2px"
                  size="mini"
                  v-show="scope.row.newStatus === 4"
                  @click="viewInformation(scope.row)"
              >{{ $t('record.checkingData') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <auditStatus ref="auditStatusDialog"/>
        <meansCrossBorderCompany ref="meansCrossBorderCompanyDialog"/>
        <meansCrossBorderPerson ref="meansCrossBorderPersonDialog"/>
        <meansMainlandPerson ref="meansMainlandPersonDialog"/>
      </div>
    </div>
  </div>
</template>
<script>
import auditStatus from '@/components/dialog/auditStatus.vue'
import meansCrossBorderCompany from '@/components/dialog/meansCrossBorderCompany.vue'
import meansCrossBorderPerson from '@/components/dialog/meansCrossBorderPerson.vue'
import meansMainlandPerson from '@/components/dialog/meansMainlandPerson.vue'

import openingReminder from '@/components/dialog/openingReminder.vue'
import {pageRecord, reviewWithdrawal, unopenedSiteList} from '@/api/base'

import Cookies from 'js-cookie'

export default {
  components: {
    auditStatus,
    meansCrossBorderCompany,
    openingReminder,
    meansCrossBorderPerson,
    meansMainlandPerson,
  },
  data() {
    return {
      siteValue: '马来西亚',
      unopenedSiteList: [],
      site: 2,
      showHome: false,
      showApplication: false,
      tableData: [],
      tableLoading: false,
      area: Number(Cookies.get('area')) || '',
      siteId: '',
      oneStoreName: ''
    }
  },
  mounted() {
    this.getPageRecord()
  },
  methods: {
    // 获取记录
    getPageRecord() {
      this.tableLoading = true
      pageRecord().then(res => {
        this.tableData = res.data.data
        this.showApplication = res.data.showApplication
        this.tableLoading = false
        this.showApplication ? this.getUnopenedSiteList() : ''
      })
    },
    // 获取未开通的站点
    getUnopenedSiteList() {
      unopenedSiteList().then(res => {
        this.unopenedSiteList = res.data
      })
    },
    oneClickApplication() {
      if (this.siteId) {
        this.$refs.openingReminderDialog.dialogVisible = true
        this.$refs.openingReminderDialog.storeName = this.oneStoreName
        this.$refs.openingReminderDialog.siteId = this.siteId
        this.siteId = ''
      } else {
        this.$message(this.$t('record.pleaseSelectSite'))
      }
    },
    // 空记录时本土和跨境跳转
    immediatelyBtn() {
      if (this.area !== 86) {
        this.$router.push({path: '/signIn/settled/mainlandPersonalShop', query: {area: this.$route.query.area}})
      } else {
        this.$router.push({path: '/signIn/settled/selectType'})
      }
    },
    // 点击状态展示说明
    explain(row) {
      this.$refs.auditStatusDialog.dialogVisible = true
      this.$refs.auditStatusDialog.site = row.site
      this.$refs.auditStatusDialog.merId = row.merId
      this.$refs.auditStatusDialog.siteUrl = row.siteUrl
      this.$refs.auditStatusDialog.getAuditDetails()
    },
    // 编辑资料
    editMaterial(row) {
      if (this.area !== 86) {
        this.$router.push({path: '/signIn/settled/mainlandPersonalShop',
          query: {
            merId: row.merId,
            id: row.id,
            site: row.site,
            newStatus: row.newStatus,
            declinedReason: row.declinedReason
          }
        })
      } else {
        // console.log("row", row);
        if (row.merType === 1) {
          this.$router.push({path: '/signIn/settled/crossBorderPersonal',
            query: {
              merId: row.merId,
              id: row.id,
              site: row.site,
              newStatus: row.newStatus,
              declinedReason: row.declinedReason
            }
          })
        } else {
          this.$router.push({path: '/signIn/settled/enterpriseStores',
            query: {
              merId: row.merId,
              id: row.id,
              site: row.site,
              newStatus: row.newStatus,
              declinedReason: row.declinedReason
            }
          })
        }
      }
    },
    // 撤回
    recall(row) {
      this.$alert(this.$t('base.subTips', ''), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('base.submit'),
        cancelButtonText: this.$t('base.cancel'),
        type: 'warning'
      }).then(() => {
        reviewWithdrawal(row.id, row.site).then(res => {
          this.$message.success(res.message)
          this.getPageRecord()
        })
      })
    },
    selectSite(val) {
      console.log("val", val);
      const siteSelect = this.unopenedSiteList.filter(item => {
        return item.id === Number(val)
      })
      this.oneStoreName = siteSelect[0].siteName
    },
    // 立即开店
    nowOpenShop(row) {
      window.location.href = row.siteUrl
    },
    // 查看资料
    viewInformation(row) {
      if (Number(row.merType) === 2 && this.area === 86) {
        this.$refs.meansCrossBorderCompanyDialog.dialogVisible = true // 跨境企业
        this.$refs.meansCrossBorderCompanyDialog.id = row.merId
        this.$refs.meansCrossBorderCompanyDialog.getStoreInformation()
      } else if (Number(row.merType) === 1 && this.area === 86) {
        this.$refs.meansCrossBorderPersonDialog.dialogVisible = true //跨境个人
        this.$refs.meansCrossBorderPersonDialog.id = row.merId
        this.$refs.meansCrossBorderPersonDialog.getStoreInformation()
      } else if (Number(row.merType) === 1 && this.area !== 86) {
        this.$refs.meansMainlandPersonDialog.dialogVisible = true //本土个人
        this.$refs.meansMainlandPersonDialog.id = row.merId
        this.$refs.meansMainlandPersonDialog.getStoreInformation()
      }
    }
  }
}
</script>
<style scoped>
.record-box {
  padding: 100px 18.5% 40px;
  box-sizing: border-box;
  display: flex;
  background: #f7f9ff;
}

.record-box-child {
  background: #fff;
  min-width: 1100px;
}

.empty-box {
  width: 100%;
  text-align: center;
  padding: 100px 0;
}

.record-box >>> .myNoteBox .cell {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

/* 去掉表格最下面的那一条线 */
.el-table::before {
  height: 0px;
}

.stateStyleOne {
  color: #4285f7 !important;
}

.stateStyleTwo {
  color: #f38117 !important;
}

.stateStyleThree {
  color: #ff243e !important;
}

.stateStyleFour {
  color: #30d197 !important;
}
</style>
