<template>
  <!-- <div> -->
  <el-dialog
    class="dialog"
    :title="$t('record.approvalStatus')"
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div v-for="(item, index) in activities" :key="index">
      <div class="d-flex align-items-center">
        <div
          class="circular-box mar-20"
          :class="{ lastStyle: (index + 1) == activities.length }"
        >{{ index + 1 }}</div>
        <div class="fs-16">{{ item.newStatus | latestStatus }}</div>
      </div>
      <div class="circular-message">
        <div class="fs-12">{{ item.createTime }}</div>
        <div v-if="item.newStatus === 1">{{$t('stores.submitTips1')}}</div>
        <div v-else-if="item.newStatus === 2">{{$t('stores.submitTips')}}</div>
        <div
          v-else-if="item.newStatus === 4"
        >{{$t('stores.passAuditTips1')}}{{ siteUrl }}{{$t('stores.passAuditTips2')}}</div>
        <div v-if="item.declinedReason">{{ item.declinedReason }}</div>
      </div>
    </div>
  </el-dialog>
  <!-- </div> -->
</template>
<script>
import { auditDetails } from '@/api/base'
export default {
  data() {
    return {
      dialogVisible: false,
      activities: [],
      reverse: true,
      site: '',
      merId: '',
      siteUrl: '',
    }
  },
  mounted() {
  },
  methods: {
    getAuditDetails() {
      auditDetails({ site: this.site, merId: this.merId }).then(res => {
        this.activities = res.data
      })
    },
    handleClose() {
      this.dialogVisible = false
    },

  },

}
</script>
<style scoped>
/* .el-dialog {
  height: 80% !important;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
} */
.circular-box {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
}
.circular-message {
  padding: 5px 30px;
  margin: 5px 15px;
  box-sizing: border-box;
  border-left: 2px solid #4285f7;
}
.lastStyle {
  background: #4285f7 !important;
  color: #fff;
  border-color: #4285f7;
}
/deep/.el-dialog__body {
  height: 600px;
  overflow-y: auto;
}
</style>
